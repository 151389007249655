import request from '../utils/request'
import baseUrl from './baseUrl'

export const evalList = (data) => request({
  url: baseUrl + '/eval/selectForBack',
  method: 'POST',
  data
})
export const evalUpdate = (data) => request({
  url: baseUrl + '/eval/update',
  method: 'POST',
  data
})