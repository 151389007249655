<template>
  <div class="evalList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>添加路线</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>联系方式：</span>
      <el-input v-model="list.phone" placeholder="请输入联系方式"></el-input>
      <span>商品名称</span>
      <el-input v-model="list.prodName" placeholder="请输入商品名称"></el-input>
      <span>评分：</span>
      <el-select v-model="list.score" placeholder="请选择评分">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
          <span>是否显示：</span>
      <el-select v-model="list.isShow" placeholder="请选择显示类型:">
        <el-option
          v-for="item in isShowCommentOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="srchComment">查找</el-button>
      <br/>
      <br/>
      <span>评论类型：</span>
      <el-select v-model="list.type" placeholder="请选择评论类型：">
        <el-option
          v-for="item in commentOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="{ row }">
          <el-form ref="form" label-width="80px">
            <el-form-item label="订单ID:">{{ row.orderId }}</el-form-item>
            <el-form-item label="订单联系人:">{{
              row.orderLinkMan
            }}</el-form-item>
            <el-form-item label="订单联系电话:">{{
              row.orderPhone
            }}</el-form-item>
            <el-form-item label="订单商品名称:">{{
              row.orderProdName
            }}</el-form-item>
            <el-form-item label="商品名称:">{{
              row.orderProdSecondName
            }}</el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <!-- <el-table-column
        align="center"
        type="selection"
        width="width"
      ></el-table-column> -->
      <el-table-column
        align="center"
        prop="evalSort"
        label="排序"
        width="width"
      >
        <template template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.evalSort"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onChangeSort(row)"
            >{{ row.evalSort }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="evalId"
        label="评论ID"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="evalCont"
        label="评论内容"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="evalImgs"
        label="评论图片"
        width="width"
      >
        <template slot-scope="{ row }">
          <el-image
            v-if="row.evalImgs"
            style="width: 50px; height: 50px"
            :src="row.evalImgs"
            :preview-src-list="[row.evalImgs]"
          ></el-image>
          <span v-if="!row.evalImgs">暂无图片评论</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="evalTime"
        label="评论时间"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="evalScore"
        label="评论分数"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="evalRespCont"
        label="评论回复"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="evalIsShow"
        label="评论是否展示"
        width="width"
      >
        <template slot-scope="{ row }">
          {{ row.evalIsShow == 0 ? "展示" : "不展示" }}
        </template>
      </el-table-column>
      <!-- <el-table-column
        align="center"
        prop="userId"
        label="用户ID"
        width="width"
      ></el-table-column> -->
      <el-table-column
        align="center"
        prop="userNickName"
        label="用户昵称"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="userHeadImgUrl"
        label="用户头像"
        width="width"
      >
        <template slot-scope="{ row }">
          <el-image
            style="width: 40px; height: 40px"
            :src="row.userHeadImgUrl"
            :preview-src-list="[row.userHeadImgUrl]"
          ></el-image> </template
      ></el-table-column>

      <el-table-column align="center" label="操作" width="150">
        <template slot-scope="{ row }">
          <el-dropdown>
            <el-button type="primary" size="mini">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                ><span @click="onReply(row.evalId)"
                  >回复
                </span></el-dropdown-item
              >
              <el-dropdown-item v-if="row.evalIsShow == 1"
                ><span @click="onIsShow(row.evalId, 0)"
                  >展示</span
                ></el-dropdown-item
              >
              <el-dropdown-item v-if="row.evalIsShow == 0"
                ><span @click="onIsShow(row.evalId, 1)"
                  >不展示</span
                ></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      title="回复评论"
      @close="onclose"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-input
        type="textarea"
        rows="6"
        v-model="replyList.respCont"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onReplyOK">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { evalList, evalUpdate } from "../../../api/eval";
export default {
  name: "evalList",
  data() {
    return {
      options: [
        { value: 1, label: "一星" },
        { value: 2, label: "二星" },
        { value: 3, label: "三星" },
        { value: 4, label: "四星" },
        { value: 5, label: "五星" },
      ],
      commentOptions: [
        { value: 1, label: "房间" },
        { value: 2, label: "酒店" },
        { value: 3, label: "门票" },
        { value: 4, label: "景区" },
        { value: 5, label: "套餐 " },
        { value: 6, label: "自由行" },
        { value: 7, label: "美食" },
      ],
      isShowCommentOptions: [
        { value: -2, label: "全部" },
        { value: 0, label: "显示" },
        { value: 1, label: "不显示" },
      ],
      list: {
        phone: "",
        score: null,
        prodName: "",
        type: null,
        currentPage: 1,
        pageSize: 10,
        isShow:0,
      },
      tableData: [],
      pagination: {},
      replyList: {
        evalId: null,
        respCont: "",
      },
      dialogVisible: false,
    };
  },
  created() {
    this.getEvalList();
  },
  methods: {
    srchComment(){
      this.getEvalList();
    },
    onIsShow(evalId, evalIsShow) {
      this.$confirm("是否设置这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await evalUpdate({
            evalId,
            evalIsShow,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getEvalList();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async onReplyOK() {
      if (this.replyList.respCont === "") {
        return this.$message.warinng("请输入回复内容");
      }
      const { data } = await evalUpdate(this.replyList);
      if (data.code == 0) {
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
      this.dialogVisible = false;
      this.getEvalList();
    },
    onReply(evalId) {
      this.dialogVisible = true;
      this.replyList.evalId = evalId;
    },
    async getEvalList() {
      const { data } = await evalList(this.list);
      if (data.code === 0) {
        this.pagination = data.pagination;
        data.list.map((item) => {
          item.inputVisible = false;
        });
        this.tableData = data.list;
      } else {
        this.$message.error(data.msg);
      }
    },
    onclose() {
      this.replyList.evalId = null;
      this.replyList.respCont = "";
    },
    onChangeSort(row) {
      console.log(row);
      row.inputVisible = true;
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },
    async handleInputConfirm(row) {
      const { data } = await evalUpdate({
        evalId: row.evalId,
        evalSort: Number(row.evalSort),
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
      } else {
        this.$message.error(data.msg);
      }
      row.inputVisible = false;
      this.getEvalList();
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getEvalList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getEvalList();
    },
  },
};
</script>
<style lang="less" scoped>
.evalList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    span {
      margin: 0 20px 0 40px;
    }
    .el-input {
      width: 200px;
    }
    .el-button {
      margin-left: 20px;
    }
  }
  /deep/ .el-table {
    margin-top: 50px;
    .el-form {
      .el-form-item {
        .el-form-item__label {
          width: 120px !important;
        }
        .el-form-item__content {
          margin-left: 120px !important;
          color: #606266;
        }
      }
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>